<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-overlay :show="loading">
              <b-row>
                <b-col lg="10" sm="12">
                  <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                <b-form  @submit.prevent="handleSubmit(register)" @reset.prevent="reset" >
                  <ValidationProvider name="Note" vid="note" rules="required">
                    <b-form-group
                      class="row"
                      label-cols-sm="3"
                      label-for="note"
                      slot-scope="{ valid, errors }"
                    >
                      <template v-slot:label>
                          {{$t('complain.note')}}<span class="text-danger">*</span>
                      </template>
                      <b-form-textarea
                        id="note"
                        v-model="reviewData.note"
                        :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-textarea>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                  <ValidationProvider name="Note (bn)" vid="note_bn" rules="required">
                    <b-form-group
                      class="row"
                      label-cols-sm="3"
                      label-for="note_bn"
                      slot-scope="{ valid, errors }"
                    >
                      <template v-slot:label>
                          {{$t('complain.note_bn')}}<span class="text-danger">*</span>
                      </template>
                      <b-form-textarea
                        id="note_bn"
                        v-model="reviewData.note_bn"
                        :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-textarea>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                  <div class="row">
                    <div class="col-sm-3"></div>
                    <div class="col text-right">
                      <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                      &nbsp;
                      <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-review')">{{ $t('globalTrans.cancel') }}</b-button>
                    </div>
                  </div>
                </b-form>
              </ValidationObserver>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { irriSchemeServiceBaseUrl } from '../../../../../config/api_config'
import { smartCardApplicationReview } from '../../api/routes'

export default {
  props: ['cardAppId'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data () {
    return {
      loading: false,
      saveBtnName: this.$t('globalTrans.save'),
      reviewData: {
        laboratory_id: 0,
        note: '',
        note_bn: ''
      },
      laboratoryList: [],
      userList: [],
      user: [],
      complainRequestList: []
    }
  },
  computed: {
    organizationtList: function () {
      const listObject = this.$store.state.commonObj.organizationProfileList
      return listObject
    }
  },
  mounted () {
    core.index()
  },
  methods: {
    async register () {
      this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }
      this.reviewData.far_smart_card_apps_id = this.cardAppId
      result = await RestApi.postData(irriSchemeServiceBaseUrl, smartCardApplicationReview, this.reviewData)
      loadinState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadinState)
      this.loading = false
      if (result.success) {
        this.$toast.success({
          title: this.$t('globalTrans.success'),
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-review')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    }
  }
}
</script>
