<template>
  <b-container fluid>
      <b-row>
        <b-col lg="12" sm="12">
          <iq-card>
            <template>
                <b-row>
                  <b-col lg="12" sm="12">
                    <b-row>
                      <b-col lg="12" sm="12">
                        <div>
                          <b-table striped small bordered hover :items="columns"  thead-class="hidden_header"></b-table>
                        </div>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
</template>
<script>
export default {
  name: 'FormLayout',
  props: ['id', 'item'],
  created () {
    this.cardApplicationRequest = this.item
  },
  data () {
    return {
      cardApplicationRequest: {},
      slOffset: 1
    }
  },
  computed: {
    columns () {
      if (this.$i18n.locale === 'bn') {
        return [
          { key: this.$t('globalTrans.organization'), val: this.cardApplicationRequest.org_name_bn, key1: this.$t('cardPayment.id_serial'), val1: this.$n(this.cardApplicationRequest.id_serial) },
          { key: this.$t('cardPayment.applicant_name'), val: this.cardApplicationRequest.name_bn, key1: this.$t('cardPayment.email'), val1: this.cardApplicationRequest.email },
          { key: this.$t('cardPayment.father_name'), val: this.cardApplicationRequest.father_name_bn, key1: this.$t('cardPayment.mother_name'), val1: this.cardApplicationRequest.mother_name_bn },
          { key: this.$t('cardPayment.marital_status'), val: this.cardApplicationRequest.marital_status_bn, key1: this.$t('cardPayment.spouse_name'), val1: this.cardApplicationRequest.spouse_name_bn },
          { key: this.$t('cardPayment.no_of_child'), val: this.$n(this.cardApplicationRequest.no_of_child, { useGrouping: false }), key1: this.$t('cardPayment.nid'), val1: this.$n(this.cardApplicationRequest.nid, { useGrouping: false }) },
          { key: this.$t('cardPayment.mobile_no'), val: this.$n('0' + this.cardApplicationRequest.mobile_no, { useGrouping: false }), key1: this.$t('cardPayment.gender'), val1: this.cardApplicationRequest.gender_bn },
          { key: this.$t('complain.division'), val: this.cardApplicationRequest.division_name_bn, key1: this.$t('complain.district'), val1: this.cardApplicationRequest.district_name_bn },
          { key: this.$t('complain.upazila'), val: this.cardApplicationRequest.upazilla_name_bn, key1: this.$t('complain.union'), val1: this.cardApplicationRequest.union_name_bn },
          { key: this.$t('farmerOperator.village'), val: this.cardApplicationRequest.far_village_bn, key1: this.$t('cardPayment.ward_no'), val1: this.$n(this.cardApplicationRequest.ward_no, { useGrouping: false }) },
          { key: this.$t('cardPayment.date_of_birth'), val: this.$d(new Date(this.cardApplicationRequest.date_of_birth)), key1: this.$t('cardPayment.qualification'), val1: this.cardApplicationRequest.qualification },
          { key: this.$t('cardPayment.owned_land'), val: this.cardApplicationRequest.owned_land, key1: this.$t('cardPayment.lease_land'), val1: this.cardApplicationRequest.lease_land },
          { key: this.$t('cardPayment.barga_land'), val: this.cardApplicationRequest.barga_land, key1: this.$t('cardPayment.total_land'), val1: this.cardApplicationRequest.total_land },
          { key: this.$t('cardPayment.training_info'), val: this.cardApplicationRequest.training_info, key1: this.$t('cardPayment.earnings'), val1: this.$n(this.cardApplicationRequest.earnings) },
          { key: this.$t('cardPayment.crop_plan'), val: this.cardApplicationRequest.crop_plan_bn, key1: this.$t('globalTrans.application_date'), val1: this.$d(new Date(this.cardApplicationRequest.created_at)) },
          { key: this.$t('globalTrans.status'), val: this.cardApplicationRequest.status_name_bn, key1: this.$t('farmerOperator.farmer_id'), val1: this.$n(this.cardApplicationRequest.farmer_id) }
        ]
      } else {
          return [
            { key: this.$t('globalTrans.organization'), val: this.cardApplicationRequest.org_name, key1: this.$t('cardPayment.id_serial'), val1: this.cardApplicationRequest.id_serial },
            { key: this.$t('cardPayment.applicant_name'), val: this.cardApplicationRequest.name, key1: this.$t('cardPayment.email'), val1: this.cardApplicationRequest.email },
            { key: this.$t('cardPayment.father_name'), val: this.cardApplicationRequest.father_name, key1: this.$t('cardPayment.mother_name'), val1: this.cardApplicationRequest.mother_name },
            { key: this.$t('cardPayment.marital_status'), val: this.cardApplicationRequest.marital_status, key1: this.$t('cardPayment.spouse_name'), val1: this.cardApplicationRequest.spouse_name },
            { key: this.$t('cardPayment.no_of_child'), val: this.cardApplicationRequest.no_of_child, key1: this.$t('cardPayment.nid'), val1: this.cardApplicationRequest.nid },
            { key: this.$t('cardPayment.mobile_no'), val: this.cardApplicationRequest.mobile_no, key1: this.$t('cardPayment.gender'), val1: this.cardApplicationRequest.gender_en },
            { key: this.$t('complain.division'), val: this.cardApplicationRequest.division_name, key1: this.$t('complain.district'), val1: this.cardApplicationRequest.district_name },
            { key: this.$t('complain.upazila'), val: this.cardApplicationRequest.upazilla_name, key1: this.$t('complain.union'), val1: this.cardApplicationRequest.union_name },
            { key: this.$t('farmerOperator.village'), val: this.cardApplicationRequest.far_village, key1: this.$t('cardPayment.ward_no'), val1: this.cardApplicationRequest.ward_no },
            { key: this.$t('cardPayment.date_of_birth'), val: this.cardApplicationRequest.date_of_birth, key1: this.$t('cardPayment.qualification'), val1: this.cardApplicationRequest.qualification },
            { key: this.$t('cardPayment.owned_land'), val: this.cardApplicationRequest.owned_land, key1: this.$t('cardPayment.lease_land'), val1: this.cardApplicationRequest.lease_land },
            { key: this.$t('cardPayment.barga_land'), val: this.cardApplicationRequest.barga_land, key1: this.$t('cardPayment.total_land'), val1: this.cardApplicationRequest.total_land },
            { key: this.$t('cardPayment.training_info'), val: this.cardApplicationRequest.training_info, key1: this.$t('cardPayment.earnings'), val1: this.cardApplicationRequest.earnings },
            { key: this.$t('cardPayment.crop_plan'), val: this.cardApplicationRequest.crop_plan, key1: this.$t('globalTrans.application_date'), val1: this.$d(new Date(this.cardApplicationRequest.created_at)) },
            { key: this.$t('globalTrans.status'), val: this.cardApplicationRequest.status_name, key1: this.$t('farmerOperator.farmer_id'), val1: this.cardApplicationRequest.farmer_id }
          ]
      }
    }
  },
  methods: {
  }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
</style>
